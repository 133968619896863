import { getTrackPostList } from '_api/follow';

export default {
  data() {
    return {
      overallData: [],
      firstOrder: [],
      secondOrder: [],
      thirdOrder: []
    };
  },
  created() {
    this.firstStage();
  },
  mounted() {
    this.reconstruction();
  },
  methods: {
    showCitySegmentPicker() {
      this.citySegmentPicker.show();
    },
    reconstruction() {
      this.citySegmentPicker = this.$createSegmentPicker({
        data: [{
          title: '第一层级',
          data: [this.firstOrder],
          selectedIndex: [0]
        }, {
          // is: 'cube-cascade-picker',
          title: '第二层级',
          data: [this.secondOrder],
          selectedIndex: [0],
          cancelTxt: '返回'
        }, {
          // is: 'cube-cascade-picker',
          title: '第三层级',
          data: [this.thirdOrder],
          selectedIndex: [0]
        }],
        cancelTxt: '取消',
        confirmTxt: '确定',
        nextTxt: '下一步',
        prevTxt: '上一步',
        onNext: (selectedVals, selectedIndexs) => {
          switch (selectedVals) {
          case 0:
            this.secondStage(selectedIndexs[0]);
            break;
          case 1:
            this.thirdStage(selectedIndexs[0]);
            break;
          }
        },
        onSelect: (selectedVals, selectedIndexs, selectedTexts) => {
          console.log(selectedTexts);
          this.model.postNameLast.push(selectedTexts[2].join(' '));
          this.postName.push(`${selectedTexts[0].join(' ')}|${selectedTexts[1].join(' ')}|${selectedTexts[2].join(' ')}`);
          this.secondOrder = [];
          this.thirdOrder = [];
          this.reconstruction();
        }
      });
    },
    firstStage() {
      getTrackPostList().then(res => {
        this.overallData = res.data.resultList;
        this.overallData.forEach(item => {
          this.firstOrder.push({text: item.postName, value: item.id});
        });
      });
    },
    secondStage(postPid) {
      this.overallData.forEach(item => {
        if (item.postList[0].postPid === postPid) {
          item.postList.forEach(items => {
            this.secondOrder.push({text: items.postName, value: items.id, postList: items.postList});
          });
        }
      });
    },
    thirdStage(postPid) {
      this.secondOrder.forEach(item => {
        if (item.postList[0].postPid === postPid) {
          item.postList.forEach(items => {
            this.thirdOrder.push({text: items.postName, value: items.id, postList: items.postList});
          });
        }
      });
    }
  }
};
