<template>
  <div class="form-custom section">
    <cube-form
      :model="model"
      class="form-wrapper"
      :options="formOptions"
      :submit-always-validate="true"
      @validate="validateHandler"
      @submit="submitHandler"
    >
      <div class="form-content">
        <cube-form-group class="form-group">
          <cube-form-item :field="fields[0]">
            <div
              class="field-wrapper"
              @click="toSearch('custCn')"
            >
              <cube-input
                v-model="model.custCn"
                class="cube-inputs"
                :readonly="true"
                placeholder="请选择"
              />
              <i
                v-if="!custDisabled"
                class="icon-joyo arrows"
              >&#xe66c;</i>
            </div>
          </cube-form-item>
          <div class="after-space border-bottom-1px" />
          <cube-form-item :field="fields[1]">
            <div
              class="field-wrapper"
              @click="toSearch('oppoCode')"
            >
              <cube-input
                v-model="model.oppoCode"
                class="cube-inputs"
                :readonly="true"
                placeholder="请选择"
              />
              <i
                v-if="!busDisabled"
                class="icon-joyo arrows"
              >&#xe66c;</i>
            </div>
            <!-- <cube-input v-model="model.oppoCode"  placeholder="请输入" :clearable="clearable" ></cube-input> -->
          </cube-form-item>
          <div class="after-space border-bottom-1px" />
          <cube-form-item :field="fields[2]">
            <div
              class="field-wrapper"
              @click="isSamepersonClick"
            >
              <cube-select
                v-model="model.trackType"
                class="cube-select"
                placeholder="请选择"
                :options="fields[2].props.options"
                :disabled="isSameperson || trackDisabled"
                @change="changeType(model.trackType, true)"
              />
              <i
                v-if="!isSameperson && !trackDisabled"
                class="icon-joyo arrows"
              >&#xe66c;</i>
            </div>
          </cube-form-item>
          <!-- <div v-if="system === 1"> -->
          <cube-form-item :field="fields[3]">
            <div
              class="field-wrapper"
              @click="toSearch('custContacts')"
            >
              <cube-input
                v-model="model.custContacts"
                class="cube-inputs"
                :readonly="true"
                placeholder="请选择"
              />
              <i class="icon-joyo arrows">&#xe66c;</i>
            </div>
          </cube-form-item>
          <!-- </div> -->
          <div class="after-space border-bottom-1px" />
          <cube-form-item :field="fields[4]">
            <div class="field-wrapper">
              <div
                class="cube-select"
                :class="changeColor(model.trackDate)"
                @click="showDatePicker('datePicker')"
              >
                {{ model.trackDate || "请选择" }}
              </div>
              <date-picker
                ref="datePicker"
                :min="[trackDateMin.getFullYear(), trackDateMin.getMonth() + 1, trackDateMin.getDate()]"
                :max="[new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate()]"
                @select="dateSelectHandler"
              />
              <i
                v-if="!isSameperson && !trackDisabled"
                class="icon-joyo arrows"
              >&#xe66c;</i>
            </div>
          </cube-form-item>
          <div
            v-show="model.trackType === 1 && (fields[5].props.options.length || model.registerCreatetime !== '')"
            class="border-bottom-1px"
          >
            <cube-form-item :field="fields[5]">
              <div class="field-wrapper">
                <span
                  v-if=" $route.query.from === 'fromSign' || ($route.query.followId && model.registerCreatetime)"
                  class="cube-select"
                  @click="isSamepersonClick"
                >{{ model.registerCreatetime }}</span>
                <cube-select
                  v-else
                  v-model="model.custRegisterId"
                  class="cube-select"
                  :options="fields[5].props.options"
                  placeholder="请选择"
                  @change="getRegisterFileInfo"
                />
                <i
                  v-if="!isSameperson && !trackDisabled"
                  class="icon-joyo arrows"
                >&#xe66c;</i>
              </div>
            </cube-form-item>
          </div>
          <div
            v-if="system === 1"
            class="after-space border-bottom-1px"
          >
            <cube-form-item :field="fields[6]" />
          </div>
          <!-- 下次跟进日期 -->
          <cube-form-item :field="fields[7]">
            <div class="field-wrapper">
              <div
                class="cube-select"
                :class="changeColor(model.nextTrackTime)"
                @click="showDatePicker('datePickern')"
              >
                {{ model.nextTrackTime || "请选择" }}
              </div>
              <date-picker
                ref="datePickern"
                :min="[2000, 1, 1]"
                :max="[2050, 1, 1]"
                @select="dateSelectHandlerNext"
              />
              <i class="icon-joyo arrows">&#xe66c;</i>
            </div>
          </cube-form-item>
          <div
            v-if="system === 1"
            class="after-space border-bottom-1px"
          >
            <cube-form-item :field="fields[8]" />
          </div>
          <cube-form-item :field="fields[9]" />
          <div
            v-if="system === 1"
            class="after-space border-bottom-1px"
          >
            <cube-form-item :field="fields[10]">
              <div class="field-wrapper">
                <div
                  class="cube-tagbox"
                  @click="getPost"
                >
                  <!-- postName -->
                  <div
                    v-if="model.postNameLast.length"
                    class="cube-taglist"
                  >
                    <span
                      v-for="(item, index) of model.postNameLast"
                      :key="index"
                      class="cube-tag"
                    >
                      {{ verdicts(item) }}
                      <i
                        class="icon-joyo clear"
                        @click.stop="delTag(index, 'postNameLast')"
                      >&#xe6ec;</i>
                    </span>
                  </div>
                  <div
                    v-else
                    class="color-ccc"
                  >
                    {{ "请选择" }}
                  </div>
                </div>
                <i class="icon-joyo arrows">&#xe66c;</i>
              </div>
            </cube-form-item>
          </div>
          <!-- 进程推进 -->
          <cube-form-item :field="fields[11]">
            <div class="field-wrapper">
              <cube-select
                v-model="model.trackReliable"
                class="cube-select"
                :options="fields[11].props.options"
                placeholder="请选择"
              />
              <i class="icon-joyo arrows">&#xe66c;</i>
            </div>
          </cube-form-item>
          <div
            v-if="reliableLogic.includes(model.trackReliable)"
            class="border-bottom-1px"
          >
            <cube-form-item :field="fields[12]">
              <div class="field-wrapper">
                <div
                  class="cube-select"
                  :class="changeColor(model.preContTime)"
                  @click="showDatePicker('datePickers')"
                >
                  {{ model.preContTime || "请选择" }}
                </div>
                <date-picker
                  ref="datePickers"
                  :min="[2000, 1, 1]"
                  :max="[2050, 1, 1]"
                  @select="datesSelectHandler"
                />
                <i class="icon-joyo arrows">&#xe66c;</i>
              </div>
            </cube-form-item>
            <!-- 预计激活时间 -->
            <cube-form-item
              :field="fields[13]"
              class="border-bottom-1px"
            >
              <div class="field-wrapper">
                <div
                  class="cube-select"
                  :class="changeColor(model.expectedActivationTime)"
                  @click="showDatePicker('datePickerd')"
                >
                  {{ model.expectedActivationTime || "请选择" }}
                </div>
                <date-picker
                  ref="datePickerd"
                  :min="[2000, 1, 1]"
                  :max="[2050, 1, 1]"
                  @select="dateSelectHandlerActivate"
                />
                <i class="icon-joyo arrows">&#xe66c;</i>
              </div>
            </cube-form-item>
            <!-- 预计激活人数 -->
            <div
              v-if="system === 1"
              class="border-bottom-1px"
            >
              <cube-form-item :field="fields[24]">
                <cube-input
                  v-model.number="model.preActivateNum"
                  type="number"
                  placeholder="请输入"
                  :clearable="clearable"
                />
              </cube-form-item>
            </div>
            <cube-form-item :field="fields[15]">
              <cube-input
                v-model="model.preProfit"
                placeholder="请输入"
                :clearable="clearable"
                @change="toMoneys(model.preProfit, 'preProfit')"
              />
            </cube-form-item>
            <cube-form-item :field="fields[16]">
              <cube-input
                v-model="model.preGrossProfit"
                placeholder="请输入"
                :clearable="clearable"
                @change="toMoneys(model.preGrossProfit, 'preGrossProfit')"
              />
            </cube-form-item>
          </div>
          <div
            v-if="model.trackReliable !== 11"
            class="after-space border-bottom-1px"
          >
            <div v-if="busTypeId === '2'">
              <cube-form-item :field="fields[25]">
                <cube-input
                  v-model.number="model.preCooperationNumSocial"
                  type="number"
                  placeholder="请输入"
                  :clearable="clearable"
                />
              </cube-form-item>
              <cube-form-item :field="fields[26]">
                <cube-input
                  v-model.number="model.preCooperationNumSalary"
                  type="number"
                  placeholder="请输入"
                  :clearable="clearable"
                />
              </cube-form-item>
            </div>
            <div v-else>
              <cube-form-item :field="fields[14]">
                <cube-input
                  v-model.number="model.preCooperationNum"
                  type="number"
                  placeholder="请输入"
                  :clearable="clearable"
                />
              </cube-form-item>
            </div>
          </div>
          <!-- 是否有供应商 -->
          <div
            v-if="system === 1"
            class="after-space border-bottom-1px"
          >
            <cube-form-item :field="fields[17]">
              <div class="field-wrapper">
                <cube-select
                  v-model="model.hasSupplier"
                  class="cube-select"
                  :options="fields[17].props.options"
                  placeholder="请选择"
                />
                <i class="icon-joyo arrows">&#xe66c;</i>
              </div>
            </cube-form-item>
          </div>
          <!-- 供应商名称 -->
          <div
            v-if="system === 1 && model.hasSupplier === 1"
            class="border-bottom-1px"
          >
            <cube-form-item :field="fields[18]">
              <cube-input
                v-model="model.supplierName"
                placeholder="请输入"
                :clearable="clearable"
              />
            </cube-form-item>
          </div>
          <div
            v-if="model.trackType === 1"
            class="after-space border-bottom-1px"
          >
            <cube-form-item :field="fields[19]">
              <div
                class="field-wrapper"
                @click="isSamepersonClick"
              >
                <cube-select
                  v-model="model.isAccompany"
                  class="cube-select"
                  :options="fields[19].props.options"
                  placeholder="请选择"
                  :disabled="isSameperson"
                  @change="judgeAccompany"
                />
                <i
                  v-if="!isSameperson"
                  class="icon-joyo arrows"
                >&#xe66c;</i>
              </div>
            </cube-form-item>
          </div>
          <div
            v-if="model.trackType === 1 && model.isAccompany === 1"
            class="after-space border-bottom-1px"
          >
            <cube-form-item :field="fields[20]">
              <div class="field-wrapper">
                <div
                  class="cube-tagbox"
                  @click="customPopupOptions"
                >
                  <div
                    v-if="model.accompanyList.length"
                    class="cube-taglist"
                  >
                    <span
                      v-for="(item, index) of model.accompanyList"
                      :key="index"
                      class="cube-tag"
                    >
                      {{ verdicts(item.text) }}
                      <i
                        v-if="!isSameperson"
                        class="icon-joyo clear"
                        @click.stop="delTagTree(index)"
                      >&#xe6ec;</i>
                    </span>
                  </div>
                  <span
                    v-else
                    class="cube-select color-ccc"
                  >{{ '请选择陪访人' }}</span>
                </div>
                <i
                  v-if="!isSameperson"
                  class="icon-joyo arrows"
                >&#xe66c;</i>
              </div>
            </cube-form-item>
            <!-- <cube-form-item :field="fields[20]">
              <div class="field-wrapper">
                <cube-select
                  v-model="model.accompanyList"
                  class="cube-select"
                  :options="fields[20].props.options"
                  placeholder="请选择"
                />
                <i class="icon-joyo arrows">&#xe66c;</i>
              </div>
            </cube-form-item> -->
          </div>
          <div
            v-if="system === 1"
            class="after-space border-bottom-1px"
          >
            <cube-form-item :field="fields[21]" />
          </div>
          <!-- 是否有效 -->
          <div
            v-if="system === 2 && model.trackType === 1"
            class="after-space border-bottom-1px"
          >
            <cube-form-item :field="fields[22]">
              <div class="field-wrapper">
                <cube-select
                  v-model="model.effective"
                  class="cube-select"
                  :options="fields[22].props.options"
                  placeholder="请选择"
                />
                <i class="icon-joyo arrows">&#xe66c;</i>
              </div>
            </cube-form-item>
          </div>
          <cube-form-item
            :field="fields[23]"
            class="no-border"
          />
          <cube-form-item />
        </cube-form-group>
      </div>
      <div class="bottom-postion btn-return-other">
        <cube-button
          v-if="$route.query.source === 'qyWeChat'"
          :light="true"
          class="btn-return"
          @click="$router.go(-1)"
        >
          返回
        </cube-button>
        <cube-button
          type="submit"
          :disabled="saveDisabled"
          class="btn-other"
          @click="judgmentTest = true"
        >
          保存
        </cube-button>
      </div>
    </cube-form>
    <search-view
      v-if="searchBool"
      :data="searchName"
      :placeholder="placeholder"
      :search-type="searchType"
      :default-func="defaultFunc"
      :func="func"
      :default-request-data="params"
      :need-btn="$route.query.source === 'qyWeChat'"
      @conceal="conceal"
      @option="option"
    />
    <router-view class="postionsTree" />
    <custom-popup
      ref="customPopups"
      :is-up="true"
    >
      <div
        slot="content"
        class="dialog-detail filter-box marginNo"
      >
        <ul class="filter-ul">
          <select-check
            v-for="(item, index) of fields[20].props.options"
            :key="index"
            :data="item"
            @click.native="subscriptOperat(index)"
          />
        </ul>
      </div>
      <div
        slot="button"
        class="bottom-div"
      >
        <div
          class="btn-div"
          @click="returns"
        >
          返回
        </div>
        <div
          class="btn-divs"
          @click="ensureBanch"
        >
          确定
        </div>
      </div>
    </custom-popup>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import { followDetail, followSave, followUpdate, getLatestTrackDataByOppoId, getRegisterFileInfoByRegisterId, getRegisterInfoByCustId, getVisitTogetherByCustId } from '_api/follow';
import { getAllCustList, getBusOppoCodeByCustId, myCustWithBusOppoList } from '_api/global';
import { getCustContactsList } from '_api/sign';
import datePicker from '_c/date-picker';
import selectCheck from '_c/picker/select-check';
import customPopup from '_c/popup/custom-popup';
import searchView from '_c/search';

import { comparTime, toMoney, toNum, verdict } from '@/lib/until.js';
import segmentPicker from '@/mixins/sgment-picker';
import config from '../../lib/config';

export default {
  name: 'AddeditFollow',
  components: {
    datePicker,
    searchView,
    customPopup,
    selectCheck
  },
  mixins: [segmentPicker],
  data() {
    return {
      isSameperson: false, // 对比登录人和创建人
      saveDisabled: false,
      searchName: '',
      searchBool: false,
      searchType: '',
      func: function() {},
      defaultFunc: function() {},
      params: {},
      placeholder: '',
      postName: [],
      custDisabled: false,
      busDisabled: false,
      trackDisabled: false,
      model: {
        orgId: '',
        custCn: '',
        custId: '',
        oppoCode: '',
        oppoId: '',
        custContacts: '',
        custContractId: '',
        trackType: '',
        trackDate: '',
        registerCreatetime: '',
        custRegisterId: '', // 签到id
        visitPurpose: '',
        custRequire: '',
        trackRemark: '',
        postNameLast: [],
        postName: [],
        preContTime: '',
        preCooperationNum: '',
        preProfit: '',
        preGrossProfit: '',
        trackReliable: '',
        isAccompany: '',
        accompanyList: [],
        fileIds: [],
        signInRemark: '',
        expectedActivationTime: '', // 预计激活时间
        preActivateNum: '', // 预计激活人数
        nextTrackTime: '', // 下次跟进日期
        hasSupplier: '', // 是否有供应商
        supplierName: '', // 供应商名称
        effective: 0, // 是否有效
        preCooperationNumSocial: '',
        preCooperationNumSalary: ''
      },
      fields: [
        // 0
        {
          type: 'input',
          modelKey: 'custCn',
          label: '客户名称',
          props: {
            disabled: true
          },
          rules: {
            required: true
          }
        },
        // 1
        {
          type: 'input',
          modelKey: 'oppoCode',
          label: '商机编号',
          props: {
            placeholder: '请输入'
          },
          rules: {
            required: true
          }
        },
        // 2
        {
          type: 'select',
          modelKey: 'trackType',
          label: '跟进类型',
          props: {
            options: []
          },
          rules: {
            required: true
          }
        },
        // 3
        {
          type: 'input',
          modelKey: 'custContacts',
          label: '本次联系人',
          props: {
            placeholder: '请选择',
            disabled: true
          },
          rules: {
            required: false
          }
        },
        // 4
        {
          modelKey: 'trackDate',
          label: '跟进日期',
          rules: {
            required: true
          }
        },
        // 5
        {
          type: 'select',
          modelKey: 'custRegisterId',
          label: '关联签到',
          props: {
            options: []
          },
          rules: {
            required: false
          }
        },
        // 6
        {
          type: 'textarea',
          modelKey: 'visitPurpose',
          label: '跟进目的',
          props: { placeholder: '请输入', maxlength: 100 },
          rules: { required: false }
        },
        // 7
        {
          modelKey: 'nextTrackTime',
          label: '下次跟进日期',
          rules: { required: true }
        },
        // 8
        {
          type: 'textarea',
          modelKey: 'custRequire',
          label: '客户需求',
          props: { placeholder: '请输入', maxlength: 500 },
          rules: { required: true }
        },
        // 9
        {
          type: 'textarea',
          modelKey: 'trackRemark',
          label: '跟进内容描述',
          props: { placeholder: '请输入', maxlength: 500 },
          rules: { required: true }
        },
        // 10
        {
          modelKey: 'postNameLast',
          label: '需求岗位'
        },
        // 11
        {
          type: 'select',
          modelKey: 'trackReliable',
          label: '进程推进',
          props: {
            options: []
          },
          rules: {
            required: true
          }
        },
        // 12
        {
          modelKey: 'preContTime',
          label: '预计签约时间',
          rules: { required: true }
        },
        // 13
        {
          modelKey: 'expectedActivationTime',
          label: '预计激活时间',
          rules: { required: true }
        },
        // 14
        {
          type: 'input',
          modelKey: 'preCooperationNum',
          label: '预计合作人数',
          props: { placeholder: '请输入', maxlength: 10 },
          rules: {
            type: 'number',
            pattern: /^[0-9]+$/,
            required: true
          }
        },
        // 15
        {
          type: 'input',
          modelKey: 'preProfit',
          label: '预计营收(元)',
          props: { placeholder: '请输入', maxlength: 20 },
          rules: {
            // type: 'number',
            // pattern: /^([1-9]\d|\d+?(\.\d{1,2})?|0.\d{1,2}|\d+)$/,
            required: true
          }
        },
        // 16
        {
          type: 'input',
          modelKey: 'preGrossProfit',
          label: '预计毛利(元)',
          props: { placeholder: '请输入', maxlength: 20 },
          rules: {
            // type: 'number',
            // pattern: /^([1-9]\d|\d+?(\.\d{1,2})?|0.\d{1,2}|\d+)$/,
            required: true
          }
        },
        // 17
        {
          type: 'select',
          modelKey: 'hasSupplier',
          label: '是否有供应商',
          props: {
            options: [
              { text: '是', value: 1 },
              { text: '否', value: 0 }
            ]
          },
          rules: {
            required: true
          }
        },
        // 18
        {
          type: 'input',
          modelKey: 'supplierName',
          label: '供应商名称',
          props: { placeholder: '请输入', maxlength: 50 },
          rules: {
            required: true
          }
        },
        // 19
        {
          type: 'select',
          modelKey: 'isAccompany',
          label: '是否有陪访人员',
          props: {
            options: [
              { text: '是', value: 1 },
              { text: '否', value: 0 }
            ]
          },
          rules: {
            required: true
          }
        },
        // 20
        {
          modelKey: 'accompanyList',
          label: '陪访人员',
          props: {
            placeholder: '请输入',
            options: []
          },
          rules: {
            required: true
          }
        },
        // 21
        {
          type: 'upload',
          modelKey: 'fileIds',
          label: '附件',
          events: {
            'file-removed': () => {},
            'file-click': (...args) => {
              const imgs = args[0].response ? [args[0].response.data.record ? args[0].response.data.record.fileUrl : ''] : [args[0].fileUrl];
              this.$createImagePreview({
                imgs: imgs
              }).show();
            },
            'input': () => {}
          },
          props: {
            max: 5,
            action: {
              target: `${config.BASE_URL}/client/tencent/getFileAndFileIdFeign`,
              headers: { token: this.$store.state.token },
              data: {
                type: 99,
                time: 0
              }
            }
          },
          rules: {
            required: false
            // uploaded: (val) => Promise.all(
            //   val.map((file, i) => new Promise((resolve, reject) => {
            //     if (file.uploadedUrl) {
            //       return resolve();
            //     }
            //     // fake request
            //     setTimeout(() => {
            //       if (i % 2) {
            //         reject(new Error());
            //       } else {
            //         file.uploadedUrl = 'uploaded/url';
            //         resolve();
            //       }
            //     }, 1000);
            //   })),
            // ).then(() => true)
          },
          messages: {
            uploaded: '上传失败'
          }
        },
        // 22
        {
          type: 'select',
          modelKey: 'effective',
          label: '是否有效',
          props: {
            options: [{ text: '是', value: 1 }, { text: '否', value: 0 }]
          },
          rules: {
            required: true
          }
        },
        // 23
        {
          type: 'textarea',
          modelKey: 'signInRemark',
          label: '备注',
          props: { placeholder: '请输入', maxlength: 50 }
        },
        // 24
        {
          type: 'input',
          modelKey: 'preActivateNum',
          label: '预计激活人数',
          props: { placeholder: '请输入', maxlength: 10 },
          rules: {
            type: 'number',
            pattern: /^[0-9]+$/
          }
        },
        // 25
        {
          type: 'input',
          modelKey: 'preCooperationNumSocial',
          label: '预计社保合作人数',
          props: { placeholder: '请输入', maxlength: 10 },
          rules: {
            type: 'number',
            pattern: /^[0-9]+$/,
            required: true
          }
        },
        // 26
        {
          type: 'input',
          modelKey: 'preCooperationNumSalary',
          label: '预计薪酬合作人数',
          props: { placeholder: '请输入', maxlength: 10 },
          rules: {
            type: 'number',
            pattern: /^[0-9]+$/,
            required: true
          }
        },
      ],
      clearable: {
        visible: true,
        blurHidden: true
      },
      formOptions: {
        scrollToInvalidField: true
      },
      judgmentTest: false,
      reliableLogic: [5, 6, 7, 8, 10],
      customPopupsBool: false, // 多选框控制
      busTypeId: ''
    };
  },
  computed: {
    ...mapGetters(['userInfo', 'dictList', 'system']),
    trackDateMin() {
      const currentDate = new Date();
      // 减去两天
      currentDate.setDate(currentDate.getDate() - 2);
      console.log(currentDate)
      return currentDate;
    }
  },
  watch: {
    'model.trackType': {
      handler(newVal) {
        if ((newVal === 1 || newVal === 3 || newVal === 5) && this.system === 2) {
          this.fields[3].rules.required = true;
        }
      }
    }
  },
  created() {
    this.getOptions();
    if (this.$route.query.busTypeId) this.busTypeId = this.$route.query.busTypeId;
  },
  mounted() {
    this.formSource();
    if (this.$route.query.followId) {
      // this.$route.meta.title = '编辑跟进';
      this.toEdit(this.$route.query.followId);
    }
    if (this.system === 1) this.fields[21].rules.required = true;
  },
  methods: {
    ...mapMutations(['SET_KEEPALIVE']),
    getReliableList(oppoStatus) {
      const reliableList = this.fields[11].props.options;
      this.fields[11].props.options = oppoStatus === 3 || oppoStatus === 6 ? reliableList.filter(item => item.value === 8 || item.value === 10) : reliableList;
    },
    toMoneys(val, type) {
      this.model[type] = toMoney(val, true);
    },
    showDatePicker(refName) {
      if (refName === 'datePicker' && this.isSameperson) {
        this.isSamepersonToast();
        return;
      }
      if (refName === 'datePicker' && this.trackDisabled) return;
      this.$refs[refName].show();
    },
    dateSelectHandler(selectedVal) {
      this.model.registerCreatetime = '';
      this.model.custRegisterId = '';
      this.model.isAccompany = '';
      this.model.accompanyList = [];
      this.model.trackDate = selectedVal; // new Date(selectedVal[0], selectedVal[1] - 1, selectedVal[2]).toDateString()
      this.aboutSign();
    },
    datesSelectHandler(selectedVal) {
      this.model.preContTime = selectedVal;
    },
    dateSelectHandlerNext(selectedVal) {
      this.model.nextTrackTime = selectedVal;
    },
    dateSelectHandlerActivate(selectedVal) {
      this.model.expectedActivationTime = selectedVal;
    },
    aboutSign() {
      if (this.model.custId) {
        if (comparTime(this.model.trackDate, '2020-12-01')) {
          if (this.model.trackType === 1) {
            this.fields[5].rules.required = true;
            getRegisterInfoByCustId(this.model.custId, this.userInfo.userInfoRes.staffId, this.model.trackDate).then(res => {
              if (res.flag) {
                this.fields[5].props.options = [];
                const options = res.data.trackRegisterDtoList || [];
                if (options.length === 0) {
                  this.model.trackDate = '';
                  this.model.custRegisterId = '';
                  this.model.registerCreatetime = '';
                  this.$showToast('未找到该客户当前时间的签到信息，请检查！');
                  return;
                } else if (options.length === 1) {
                  this.model.custRegisterId = options[0].id;
                  this.getRegisterFileInfo(options[0].id);
                }
                options.forEach(item => {
                  this.fields[5].props.options.push(
                    {
                      text: item.createTime,
                      value: item.id
                    }
                  );
                });
              } else {
                this.$showToast(res.errorMsg);
              }
            });
          }
        } else {
          this.fields[5].rules.required = false;
          this.fields[5].props.options = [];
          this.model.custRegisterId = '';
          this.model.registerCreatetime = '';
        }
      } else {
        this.$showToast('请先选择客户名称');
      }
    },
    toSearch(type) {
      if (type === 'custCn' && this.custDisabled) {
        return;
      } else if (type === 'oppoCode' && this.busDisabled) {
        return;
      }
      switch (type) {
        case 'custCn':
          this.placeholder = '请输入客户名称';
          this.defaultFunc = myCustWithBusOppoList;
          this.func = getAllCustList;
          // this.searchName = this.model.custCn;
          break;
        case 'oppoCode':
          if (this.model.custCn === '') {
            return this.$showToast('请先选择客户名称');
          }
          this.placeholder = '请输入商机编号或产品名称精确查询';
          // this.searchName = this.model.oppoCode;
          this.defaultFunc = getBusOppoCodeByCustId;
          this.params = { custId: this.model.custId, staffId: this.userInfo.userInfoRes.staffId, needShareOppoFlag: true };
          this.func = getBusOppoCodeByCustId;
          break;
        case 'custContacts':
          if (this.model.custCn === '') {
            return this.$showToast('请先选择客户名称');
          }
          this.SET_KEEPALIVE(['AddeditFollow']); // 离开页面设置缓存
          this.placeholder = '请输入联系人姓名精确查询';
          // this.searchName = this.model.custContacts;
          this.defaultFunc = getCustContactsList;
          this.params = { custId: this.model.custId, pageNum: 1, pageSize: 10 };
          this.func = getCustContactsList;
          break;
      }
      this.searchType = type;
      this.searchBool = true;
    },
    conceal(bool) {
      this.searchBool = bool;
    },
    option(data) {
      switch (this.searchType) {
        case 'custCn':
          this.model.custId = data.id;
          this.model.oppoId = '';
          this.model.custContractId = '';
          this.model.oppoCode = '';
          this.model.custContacts = '';
          break;
        case 'oppoCode':
          this.model.oppoId = data.id;
          this.busTypeId = data.busTypeId + '';
          this.lastFollow();
          break;
        case 'custContacts':
          this.model.custContractId = data.id;
          break;
      }
      this.model[this.searchType] = data.title;
      this.searchBool = false;
    },
    lastFollow() {
      getLatestTrackDataByOppoId(this.model.oppoId).then(res => {
        if (res.flag) {
          if (res.data !== null) {
            const lastData = ['postName', 'custRequire', 'trackReliable', 'preContTime', 'preCooperationNum', 'preCooperationNumSocial', 'preCooperationNumSalary', 'preProfit', 'preGrossProfit', 'expectedActivationTime', 'hasSupplier', 'supplierName', 'preActivateNum'];
            lastData.forEach(item => {
              this.model[item] = res.data[item];
            });
            this.model.custRequire = this.model.custRequire || '';
            // 转千分符；
            // this.toMoneys(this.model.preProfit, 'preProfit');
            // this.toMoneys(this.model.preGrossProfit, 'preGrossProfit');
            // 需求岗位；
            res.data.postName && this.disposePostName(res.data.postName);
          }
        } else {
          this.$showToast(res.errorMsg);
        }
      });
    },
    changeColor(value) {
      if (value !== '' && value !== null) {
        return 'color-333';
      }
      return 'color-ccc';
    },
    getPost() {
      this.SET_KEEPALIVE(['AddeditFollow']); // 离开页面设置缓存
      this.showCitySegmentPicker();
    },
    getTree() {
      this.SET_KEEPALIVE(['AddeditFollow']);
      this.$router.push({
        name: 'follow-tree',
        params: {
          routerName: this.$route.name,
          typeName: 'multiple'
        }
      });
    },
    verdicts(str) {
      return verdict(str, 6);
    },
    delTag(index) {
      this.model.postNameLast.splice(index, 1);
      this.postName.splice(index, 1);
    },
    getOptions() {
      this.fields[2].props.options = this.dictList.trackType;
      this.fields[11].props.options = this.dictList.reliable;
    },
    changeType(val, isChange = false) {
      if (val === 1) {
        this.fields[3].rules.required = true;
        this.fields[5].rules.required = true;
        this.fields[6].rules.required = true;
        this.fields[22].rules.required = true;
        this.fields[21].rules.required = true;
      } else {
        this.fields[3].rules.required = false;
        this.fields[5].rules.required = false;
        this.fields[6].rules.required = false;
        this.fields[22].rules.required = false;
        this.fields[21].rules.required = false;
        if (this.system === 2) this.model.effective = 0;
      }
      if (isChange) {
        this.model.isAccompany = '';
        this.model.accompanyList = [];
      }
    },
    disposePostName(postName) {
      if (postName !== '') {
        const postNameLast = postName.split(',');
        postNameLast.forEach(item => {
          const itemArr = item.split('|');
          this.model.postNameLast.push(itemArr[itemArr.length - 1]);
        });
        this.postName = postNameLast;
      }
    },
    // 获取签到默认附件
    getRegisterFileInfo(id) {
      if (!this.$route.query.followId && this.system === 1) {
        getRegisterFileInfoByRegisterId(id).then(res => {
          if (res.flag) {
            this.processAttachment(res.data.fileDetailList);
          } else {
            this.$showToast(res.errorMsg);
          }
        });
      }
    },
    // 进入来源
    formSource() {
      switch (this.$route.query.from) {
        case 'fromCust':
          this.fromCust();
          break;
        case 'fromSign':
          this.fromSign();
          break;
        case 'fromBus':
          this.fromBus();
          break;
      }
      // const data = {
      //   'fromCust': this.formCust,
      //   'fromSign': this.fromSign,
      //   'fromBus': this.fromBus
      // };
      // data[something] && data[something]();
    },
    // 从客户进入
    fromCust() {
      this.custDisabled = true;
      const { custId, custCn } = this.$route.query;
      if (custId && custCn) {
        this.model.custId = custId;
        this.model.custCn = custCn;
      }
    },
    // 从商机进入
    fromBus() {
      this.custDisabled = true;
      this.busDisabled = true;
      const { custId, custCn, oppoCode, oppoId, comboName } = this.$route.query;
      if (custId && custCn && oppoCode && oppoId) {
        this.model.custId = custId;
        this.model.custCn = custCn;
        this.model.oppoCode = oppoCode + '-' + comboName;
        this.model.oppoId = oppoId;
        this.lastFollow();
      }
    },
    // 从首页进入
    fromSign() {
      this.custDisabled = true;
      this.trackDisabled = true;
      const { custId, custCn, trackTime, signId } = this.$route.query;
      if (custId && custCn && trackTime) {
        this.model.trackType = 1;
        this.model.custId = custId;
        this.model.custCn = custCn;
        this.model.trackDate = trackTime.substring(0, 10);
        this.model.registerCreatetime = trackTime.substring(0, trackTime.length);
        this.model.custRegisterId = signId;
        this.changeType(this.model.trackType);
      }
    },
    // 编辑
    toEdit(followId) {
      this.custDisabled = true;
      this.busDisabled = true;
      followDetail(followId).then(res => {
        if (res.data) {
          const { busoppoInfoDetail, custContactsDetail, oppoTrackDetail } = res.data;
          this.model = Object.assign(this.model, oppoTrackDetail);
          this.busTypeId = busoppoInfoDetail.busTypeIds;
          // cube-ui 判断非null
          this.model.visitPurpose = oppoTrackDetail.visitPurpose || '';
          this.model.custRequire = oppoTrackDetail.custRequire || '';
          this.model.trackRemark = oppoTrackDetail.trackRemark || '';
          this.model.signInRemark = oppoTrackDetail.signInRemark || '';
          // 客户商机
          this.model.custCn = busoppoInfoDetail.custCn;
          this.model.custId = busoppoInfoDetail.custId;
          this.model.oppoCode = busoppoInfoDetail.oppoCode;
          this.model.oppoId = busoppoInfoDetail.id;
          // 控制本次联系人
          this.changeType(this.model.trackType);
          this.model.custContacts = (custContactsDetail.name ? custContactsDetail.name : '') + (custContactsDetail.position ? custContactsDetail.position : '') + (custContactsDetail.mobile ? custContactsDetail.mobile : '');
          this.model.custContractId = custContactsDetail.id;
          // 关联签到
          // this.model.registerCreatetime = oppoTrackDetail.registerCreatetime;
          // this.model.custRegisterId = oppoTrackDetail.custRegisterId;
          // 处理需求岗位
          this.disposePostName(oppoTrackDetail.postName);
          // 处理千分符
          this.toMoneys(this.model.preProfit, 'preProfit');
          this.toMoneys(this.model.preGrossProfit, 'preGrossProfit');
          // 陪访人员
          if (oppoTrackDetail.accompanys !== null) {
            oppoTrackDetail.accompanys.forEach(item => {
              this.model.accompanyList.push({ text: item.realName, value: item.staffId, dep: false });
            });
          }
          // 判断创建人和登录人是否相同
          if (this.model.userId !== this.userInfo.userInfoRes.staffId) this.isSameperson = true;
          // 获取陪访列表
          // this.judgeAccompany(this.model.isAccompany, 'edit');
          if (this.model.isAccompany === 1) {
            if (this.isSameperson) {
              this.model.accompanys.forEach(item => {
                this.fields[20].props.options.push(
                  {
                    val: item.realName,
                    key: item.staffId,
                    dep: false
                  }
                );
              });
              this.customPopupsBool = true;
            } else {
              this.judgeAccompany(this.model.isAccompany, 'edit');
            }
          }
          // 附件bug；
          this.processAttachment(oppoTrackDetail.fileDetails);
          // 判断进程推进options
          this.getReliableList(busoppoInfoDetail.oppoStatus);
        } else {
          this.$showToast(res.errorMsg);
        }
      });
    },
    // 处理附件显示
    processAttachment(arr) {
      if (this.system === 1) {
        arr.forEach((item, index) => {
          this.model.fileIds.push(item);
          this.$nextTick(() => {
            const imgObj = document.getElementsByClassName('cube-upload-file-state');
            const textObj = document.getElementsByClassName('cube-upload-file-progress');
            imgObj[index].style.backgroundImage = `url(${item.fileUrl})`;// 设置背景图的的地址
            imgObj[index].style.backgroundRepeat = 'no-repeat';// 设置背景不平铺
            imgObj[index].style.backgroundPosition = 'center';// 设置背景图的位置
            imgObj[index].style.backgroundSize = 'cover';// 设置背景图像的尺寸
            imgObj[index].style.opacity = 1;
            textObj[index].style.display = 'none';
          });
        });
      }
    },
    // 效验报错提示
    validateHandler(result) {
      if (this.judgmentTest) {
        if (!result.valid) {
          this.judgmentTest = false;
          for (const i in result.validity) {
            if (result.validity[i].valid === false) {
              for (let j = 0; j < this.fields.length; j++) {
                const item = this.fields[j];
                if (item.modelKey === i) {
                  this.$showToast(item.label + '为空，不能保存');
                  return;
                }
              }
            }
          }
        }
      }
    },
    // 提交
    submitHandler(e) {
      e.preventDefault();
      this.saveDisabled = true;
      const params = JSON.parse(JSON.stringify(this.model));
      params.orgId = this.userInfo.postRoleResList[0].orgId;
      params.postName = this.system === 1 ? this.postName.join(',') : '';
      params.custRequire = this.system === 1 ? params.custRequire : '';
      params.fileIds = params.fileIds.map(item => item.response ? item.response.data.record.fileId : item.fileId);
      params.preContTime = this.reliableLogic.includes(params.trackReliable) ? params.preContTime : '';
      params.preCooperationNum = this.model.trackReliable !== 11 && this.busTypeId !== '2' ? params.preCooperationNum : '';
      // crm1.8.8新增
      params.preCooperationNumSocial = this.model.trackReliable !== 11 && this.busTypeId === '2' ? params.preCooperationNumSocial : '';
      params.preCooperationNumSalary = this.model.trackReliable !== 11 && this.busTypeId === '2' ? params.preCooperationNumSalary : '';
      params.preProfit = this.reliableLogic.includes(params.trackReliable) ? toNum(params.preProfit) : '';
      params.preGrossProfit = this.reliableLogic.includes(params.trackReliable) ? toNum(params.preGrossProfit) : '';
      params.expectedActivationTime = this.reliableLogic.includes(params.trackReliable) ? params.expectedActivationTime : '';
      params.preActivateNum = this.system === 1 && this.reliableLogic.includes(params.trackReliable) ? params.preActivateNum : '';
      params.hasSupplier = this.system === 1 ? params.hasSupplier : null;
      params.supplierName = params.hasSupplier === 1 ? params.supplierName : '';
      params.effective = this.system === 2 ? params.effective : params.trackType === 1 ? 1 : 0;
      params.isAccompany = params.trackType === 1 ? params.isAccompany !== '' ? params.isAccompany : params.isAccompany = 0 : 0;
      params.accompanyIds = params.trackType === 1 && params.isAccompany === 1 ? params.accompanyList.map(item => item.value).join(',') : '';
      params.accompanyList = [];
      params.accompanys = [];
      if (this.$route.query.followId) {
        params.trackId = this.$route.query.followId;
        this.$loading.show();
        followUpdate(params).then(res => { // 编辑
          this.$loading.hide();
          if (res.flag) {
            this.$router.go(-1);
            this.$showToast('保存成功');
            this.saveDisabled = false;
            this.SET_KEEPALIVE([]);
          } else {
            this.$showToast(res.errorMsg);
            this.saveDisabled = false;
            this.SET_KEEPALIVE([]);
          }
        });
      } else {
        followSave(params).then(res => { // 新增
          if (res.flag) {
            if (this.$route.query.from === 'fromSign') {
              this.$router.push(
                {
                  name: 'follow-details',
                  params: {
                    followId: res.data.trackId
                  }
                }
              );
            } else {
              this.$router.go(-1);
            };
            this.$showToast('保存成功');
            this.saveDisabled = false;
            this.SET_KEEPALIVE([]);
          } else {
            this.$showToast(res.errorMsg);
            this.saveDisabled = false;
            this.SET_KEEPALIVE([]);
          }
        });
      }
    },
    // 根据客户id获取所有的陪访人信息
    judgeAccompany(val, type = 'add') {
      if (val === 1) {
        if (type === 'add') this.$showToast('请选择陪访人!');
        if (this.model.trackDate) {
          this.fields[20].props.options = [];
          getVisitTogetherByCustId(this.model.custId, this.model.trackDate).then(res => {
            if (res.flag) {
              res.data.list.forEach(item => {
                this.fields[20].props.options.push(
                  {
                    text: item.staffName,
                    value: item.staffId,
                    dep: false
                  }
                );
              });
              this.customPopupsBool = true;
            } else {
              this.customPopupsBool = false;
              this.$showToast(res.errorMsg);
            }
          });
        } else {
          this.$showToast('请先维护跟进日期!');
        }
      }
    },
    // 选择陪访人操作
    ensureBanch() {
      this.model.accompanyList = [];
      this.fields[20].props.options.forEach(item => {
        if (item.dep === true) {
          this.model.accompanyList.push(item);
        }
      });
      this.returns();
    },
    // 打开陪访多选框
    customPopupOptions() {
      if (!this.isSameperson) {
        if (this.model.trackDate) {
          if (this.customPopupsBool) {
            this.$refs.customPopups.isShow = true;
          } else {
            this.$showToast('未找到其他签到人员信息');
          }
        } else {
          this.$showToast('请先维护跟进日期!');
        }
      } else {
        this.isSamepersonToast();
      }
    },
    // 关闭多选框
    returns() {
      this.$refs.customPopups.isShow = false;
    },
    // 选中陪访tag
    subscriptOperat(index) {
      this.fields[20].props.options[index].dep = !this.fields[20].props.options[index].dep;
    },
    // 删除陪访tag
    delTagTree(index) {
      this.model.accompanyList.splice(index, 1);
      this.fields[20].props.options[index].dep = false;
    },
    isSamepersonClick() {
      if (this.isSameperson) this.isSamepersonToast();
    },
    isSamepersonToast() {
      this.$showToast('非跟进创建人，此项不可编辑');
    }
  }
};
</script>

<style lang="scss" scoped src="@/assets/css/components/addstyle.scss"></style>
<style lang="scss" scoped src="@/assets/css/components/btn-bottom.scss"></style>
<style lang="scss" scoped  src="@/assets/css/components/filter.scss"></style>
<style lang="scss" scoped>
.cube-tag {
  position: relative;
  display: inline-block;
  height: 20px;
  margin: 0 15px 10px 0;
  padding: 10px 8px;
  font-size: $font-14;
  background-color: #f0f0f0;
  border-radius: 2px;
  .clear {
    position: absolute;
    top: -8px;
    right: -8px;
    color: $color-CCC;
    font-size: $font-15;
  }
}

.postionsTree {
  position: absolute;
  top: 0;
  z-index: 10;
  width: 100%;
}
.no-border::after {
  border-color:transparent;
}
.dialog-detail{
  z-index: 102;
  height: calc(90vh - 46px);
  overflow-y: auto;
}
.marginNo{
  margin: 0
}
</style>
